<template>
  <div class="pbf grid_width reviews_boxs">



    <div class="sortb">
      <UiSort 
        @choose="chooseSort"
        :val="sort"    
        :options="sortOptions"
        />
    </div>

 


      <SkBasicRowsBold 
        v-if="is_loading && start == 0"
        />

      <template v-else v-for="pro in review_data.items"> 
        <GeneralReview
          :id="pro.id" 
          :strain_comment="pro.text" 
          :comment="pro.comment" 
          :strain_general="pro?.n_general" 
          :post_id="pro.post_id" 
          :add_date="pro.add_date" 
          :strain_resistance="pro?.props?.resistance" 
          :strain_growing="pro?.props?.growing" 
          :item_diary="pro.item_diary" 
          :item_product="pro.item_product" 
          :item_user="pro.item_user"
          :items_product="pro.items_product"
          />
      </template> 

      <client-only>        
        <InfinityScroll 
          v-if="!is_ended"
          :is-loading="is_loading"
          ref="el_infinity_scroll"/>
      </client-only>


  </div>
</template>

<script setup>



import { useIntersectionObserver } from '@vueuse/core'
import { watchArray } from '@vueuse/core'

const is_ended = ref(false)
const {$api, $adv, $tagsUtil, $ga} = useNuxtApp()
const el_infinity_scroll = ref(null)

const props = defineProps({
  inurl: {
    type: String,
    required: true
  },
  intags: {
    type: Object,
    required: true
  },
  sortOptions: {
    type: Array,
    required: true
  },
  brandId: {
    type: Number,
    default: null
  },
  productId: {
    type: Number,
    default: null
  },
  growerId: {
    type: Number,
    default: null
  },
  strainId: {
    type: Number,
    required: false
  }
})

const start = ref(0);
const limit = ref(20);
const query = ref('');
const sort = ref('create');
const tagscount = ref([]);
const tagsselected = ref([]);
const tagspermanent = ref([]);
const tagsdefault = ref(null);
const list = ref([]);



if(props.strainId){
  tagspermanent.value.push('st:' + props.strainId);
}

 
const clearPagination = function(){     
  start.value = 0;  
}


const unchooseTags = function(selected){  
  tagsselected.value = tagsselected.value.filter((item) => item != selected);
}        
const chooseTags = function(selected){          
  tagsselected.value.push(selected);      
  clearPagination();    
  $ga.eventGA('explore', 'tag', $tagsUtil.getTagsSimple(props.intags, tagsselected.value, tagspermanent.value));
}

const chooseSort = function(selected_sort){   
  sort.value = selected_sort;    
  clearPagination();  
}

 



const loadData = async function() {  
  const response = await $api.getUrl(props.inurl, {
    start: start.value,
    limit: limit.value,
    q: $tagsUtil.getTagsKeyword(props.intags, tagsselected.value, tagspermanent.value),       
    sortable: sort.value,
    tagsall: $tagsUtil.getTagsKeys(props.intags),
    tags: $tagsUtil.getTagsSimple(props.intags, tagsselected.value, tagspermanent.value)
  });
  return response;  
}



const { pending: is_loading,  data: review_data } = await useLazyAsyncData('review_data', async () => await loadData())


watchArray([start, sort, tagsselected], async ([new_start, new_sort, new_tagsselected], added, removed) => {
  
  if(new_start == 0){
    review_data.value.items = [];
    is_ended.value = false;
  }
  
  console.log('watcher');
  is_loading.value = true;
  var dt = await loadData();

    
  if(dt.items?.length == 0)
    is_ended.value = true;  

  review_data.value.items = [...review_data.value.items, ...dt.items]
  // review_data.value.items_tags = [...review_data.value.items_tags, ...dt.items_tags]
  is_loading.value = false;

  // $adv.apply();

}, {deep: true})



useIntersectionObserver(
  el_infinity_scroll,
  ([{isIntersecting}]) => {
    if(isIntersecting){
      console.log('infinity scroll event');        
      if(!is_loading.value && !is_ended.value)
        start.value = start.value + limit.value;
    }
  },
  { distance: 10 }
)

</script>

<style scoped>

.sortb{
  margin-left: auto;
  margin-right: 0;
  width: fit-content;
}
.add_filter_box{
  display: flex;
  margin-left: auto;
  align-self: baseline;
}

</style>

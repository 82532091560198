<template>
  

  <div class="rw skl"></div>
  <div class="rw skl"></div>
  <div class="rw skl"></div>
  <div class="rw skl"></div>
  <div class="rw skl"></div> 

 
</template>


<script setup>
 
</script>


<style scoped>

.rw{  
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
  gap: 11px;
  margin: 1.2rem auto;
} 

.rw > *{
  flex-shrink: 0;
}
 

</style>
